import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'
import tw, { styled, css } from "twin.macro"

export const Wiggle = ({ rotation = 0, delay = 1500, children, customStyle }) => {
  const style = useSpring({
    loop: true,
    to:[
      {transform: `rotate(0deg)`},
    ],
    from:{transform: `rotate(${rotation}deg)`},
      config: { 
        mass:1,
        tension: 300,
        friction: 5, },
    delay: {delay},
  });

  return (
    <animated.div style={style} css={customStyle}>
      {children}
    </animated.div>
  );
};


