import React, { useEffect } from "react"
import tw, { styled, css } from "twin.macro"
import { Wiggle } from "./Wiggle"
import {useSnapshot } from "valtio"
import { VolumeOff, VolumeUp } from "@styled-icons/material-rounded"
import backgroundMusic from '../Music/bgs.webm'
import backgroundMusic2 from '../Music/bgs.mp3'
import useSound from 'use-sound';
import state,{stateActions} from './Store'

export const SoundPlayer = ({ children }) => {
  const snap = useSnapshot(state)
  const [play, {sound, pause}] = useSound([backgroundMusic,backgroundMusic2], {volume: 0.5,  loop: true})
  useEffect(() => {
    if(snap.playSong){
      if(snap.playSongFrom){
      sound?.seek(snap.playSongFrom)
    }
    play()
    sound?.fade(0, 0.5, 1500);
    }
    else{
      let timeStamp = sound?.seek()
      state.playSongFrom = timeStamp
      pause()
    }
    return () => {
      let timeStamp = sound?.seek()
      state.playSongFrom = timeStamp
    sound?.fade(0.5, 0, 1500);
    }
  }, [snap.playSong, sound])
    return(
    <>
      {children}
      <div
        tw={
          "fixed bottom-0 right-0 p-6 object-right-bottom flex justify-center align-items[center] text-gray-50 text-xl z-index[60] "
        }
      >
        <Wiggle
          rotation={20}
          timing={200}
          customStyle={tw`flex align-content[center] justify-center`}
        >
          {snap.playSong ? (
            <button
              tw={"m-2 w-7 h-7"}
              title={"Mute"}
              onClick={() => (state.playSong = false)}
            >
              <VolumeUp tw="filter drop-shadow-lg"/>
            </button>
          ) : (
            <button
              tw={"m-2 w-7 h-7"}
              title={"Unmute"}
              onClick={() => (state.playSong = true)}
            >
              <VolumeOff tw="filter drop-shadow-lg"/>
            </button>
          )}
        </Wiggle>
      </div>
    </>
  )
          }