/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'tailwindcss/dist/base.css'
import React from 'react'
import {SoundPlayer} from './src/components/SoundPlayer'
const soundActivePages = ["/follicle","/about","/toxi-cartography"]
export const wrapPageElement = ({ element, props }) => {
    if(soundActivePages.indexOf(props.uri) > -1){
    return <SoundPlayer {...props}>{element}</SoundPlayer>
    }
    else{
        return <React.Fragment>{element}</React.Fragment>
    }
  };