import { proxy, useSnapshot } from "valtio"
import {Targets} from '../data/elements'
const state = proxy({
    currentSelected: null,
    currentSelectedColor: "#FFFFFF",
    isolateMaterial: null,
    isolateCity: null,
    isolateConnectedCities: null,
    xOffset: 0,
    yOffset: 0,
    zOffset: 0,
    playSong: false,
    playSongFrom: 0,
    showMatTagOn:"10560",
  })
  export default state;

  export const stateActions = {
    initializeMatTag:()=>{
      state.showMatTagOn ="10560"
    },
    updateCameraQuad:(rad)=>{
      let value= rad*(180/Math.PI)
      let angle = value<0 ? 360 + value : value
      if(angle>306 || angle<26) state.showMatTagOn = "10560"
      else if(26<angle && angle<138) state.showMatTagOn = "12120"
      else if(138<angle && angle<221) state.showMatTagOn = "11120"
      else if(221<angle && angle<306) state.showMatTagOn = "10150"
    },
    deselectAll:()=>{
      state.isolateCity = null 
      state.isolateMaterial = null
      state.isolateConnectedCities = null
    },
    updateCity:(city)=>{
      state.isolateCity = city 
      if(!state.isolateMaterial) state.isolateConnectedCities = Targets[city]
    },
    selectedCity: (city)=>{
      if(state.isolateCity == city){
        state.isolateCity = null
        state.isolateConnectedCities=null
      }
      else{
        state.isolateCity = city 
        if(!state.isolateMaterial) state.isolateConnectedCities = Targets[city]
      }
    },
    updateMaterial:(material)=>{
      state.isolateMaterial = material
    },
    selectedMaterial: (material) => {
      if(state.isolateMaterial == material){
        state.isolateMaterial = null
      }
      else{
        state.isolateMaterial = material
      }
    },
    deselectMaterial:()=>{
      state.isolateMaterial = null
    },
    deselectCity:()=>{
      state.isolateCity = null
      state.isolateConnectedCities=null
    },

  }
